
.card {
    flex-direction: row;
  }

  .card-img {
    max-width: 300px;
    max-height: 300px;
  }

  .card-text {
    font-size: normal;
  }

  @media only screen and (max-width: 600px) {
    .card {
      flex-direction:column;
    }

  }

  .podcastsection {
    max-width: auto;
    margin: 2rem auto;
    background-color: rgb(255, 255, 255);
    padding: 1rem;
    /* border-radius: 12px; */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
  }